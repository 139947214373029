import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COLOR_PALETTE as CP } from '@ws/constants';
import { environment } from '@app/env';
import { Auth_Service } from '@app/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class Reset_Password_Component {
  form: FormGroup;
  logo_fill = CP.PURPLE;
  privacy_policy = environment.urls.pp;
  public_app_link = environment.urls.public;
  submitted = false;
  submitting = false;
  success = false;

  constructor(
    private auth_s: Auth_Service,
    private fb: FormBuilder,
    private message_s: NzMessageService,
    ) {
    this.form = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }

  private get_error_message(error: any): string {
    let m = '';
    switch(error.code) {
      case 'auth/user-not-found':
        m = 'That email address is not registered.';
        break;
      default:
        m = error.message
    }

    return m;
  }

  async submit_form(): Promise<void> {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    if (!this.form.valid) {
      this.submitting = false;
      return;
    }

    const email = this.form.get('email')! && this.form.get('email')!.value;
    if (!email) {
      return;
    }

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      this.form.controls[key].disable();
    })

    try {
      await this.auth_s.send_password_reset_email(email);
      this.success = true;
      this.submitted = true;
    } catch (e) {
      const message = this.get_error_message(e);
      this.message_s.error(message, { nzDuration: 4000 });
      // Re-enabled the form controls.
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].enable({emitEvent: false});
      });
    } finally {
      this.submitting = false;
    }
  }

  try_again() {
    this.submitted = false;
  }
}
