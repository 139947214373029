import { Environment } from './env-interface'
import { App_Config } from '@ws/constants'
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration main` replaces `environment.ts` with `environment.main.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  env_name: App_Config.ENV_NAMES.MAIN,
  firebase_config: {
    apiKey: "AIzaSyDZ9NEA8Jtitw8ldlQ8lFoM-D1DhoAGRIQ",
    authDomain: "jessup-main.firebaseapp.com",
    databaseURL: "https://jessup-main-default-rtdb.firebaseio.com",
    projectId: "jessup-main",
    storageBucket: "jessup-main.appspot.com",
    messagingSenderId: "203056285117",
    appId: "1:203056285117:web:93e751f87a2a4ab4f13ed4",
    measurementId: "G-GS241D40S1"
  },
  production: true,
  urls: App_Config.Domains.jessup.main,
  use_local: false,
}
