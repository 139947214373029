import { FB_Refs_Rtdb as rtdb_refs } from './rtdb-refs'

export class RTDB_Jessup_Refs {
  static jessup_eid() {
    return 'jessup_eid'
  }

  static assemble_court(event_id: string, room_id: string) {
    return  `${rtdb_refs._root(event_id)}/assemble_court/${room_id}`
  }

  static emp_ilsa_id(event_id: string, user_id: string) {
    return `${rtdb_refs.event_member_public_root(event_id)}/${user_id}/ilsa_id`
  }

  static emp_ilsa_role(event_id: string, user_id: string) {
    return `${rtdb_refs.event_member_public_root(event_id)}/${user_id}/role`
  }

  static matches_ready(event_id: string) {
    return `${rtdb_refs._root(event_id)}/matches_ready`
  }

  static match_judges(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/judges/members`
  }

  static match_app_memorial(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/memorial_link`
  }

  static match_app_observers(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/app_observers`
  }

  static match_app_team_id(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/name`
  }

  static match_app_users(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/members`
  }

  static match_res_memorial(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/memorial_link`
  }

  static match_res_observers(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/res_observers`
  }

  static match_res_team_id(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/name`
  }

  static match_res_users(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/members`
  }

  static match_room_link(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/room_link`
  }

  static match_room_name(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/name`
  }

  static match_room_name2(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/room_name`
  }

  static match_open_time(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/open_time`
  }

  static user_matches(p: { eid: string }) {
    return `${rtdb_refs._root(p.eid)}/user_matches`
  }

  static user_matches_pid(p: {
    eid: string, pid: string
  }) {
    return `${this.user_matches(p)}/${p.pid}`
  }

  static user_matches_ilsa_id(p: {
    eid: string, pid: string, ilsa_id: string
  }) {
    return `${this.user_matches_pid(p)}/${p.ilsa_id}`
  }

  static user_matches_mid(p: {
    eid: string, pid: string, ilsa_id: string, mid: string,
  }) {
    return `${this.user_matches_ilsa_id(p)}/${p.mid}`
  }

  static match_memorial_score(event_id: string, match_id: string) {
    return `${rtdb_refs._root(event_id)}/memorial_score/${match_id}`
  }
}
