import { FB_Refs_Rtdb as rtdb_refs } from '@ws/constants'

export class RTDB_Moot_Refs {
  static match_speakers(event_id: string, room_id: string) {
    return `${rtdb_refs._root(event_id)}/court_speakers/${room_id}`
  }

  static match_speaker_app(event_id: string, room_id: string) {
    return `${this.match_speakers(event_id, room_id)}/app`
  }

  static match_speaker_res(event_id: string, room_id: string) {
    return `${this.match_speakers(event_id, room_id)}/res`
  }
}
