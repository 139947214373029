import {
  DocumentSnapshot as FSDSS,
  QuerySnapshot as QSS,
  QueryDocumentSnapshot as QDSS,
} from '@firebase/firestore-types'
import { GUID as FSG, GUID_Type as FSGT} from '@ws/schema-fs'
import { GUID as RTDBG, GUID_Type as RTDBGT} from '@ws/schema-rtdb'
import * as firebase from 'firebase/app'


// ******************************************
// * FIRESTORE
// ******************************************
export function unpack_fs_doc<T>(query: FSDSS | Error) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (!query.exists) {
    return undefined
  }

  return Object.assign(<T>{}, query.data(), { id: query.id })
}

// Unpacks the Firestore-ness of a collection query.
export function unpack_fs_collection<T>(query: QSS | Error) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (query.empty) {
    return undefined
  }

  const collection = {} as Record<FSG, T & FSGT>

  query.docs.forEach((item: QDSS) => {
    collection[item.id] = Object.assign(<T>{}, item.data(), { id: item.id })
  })

  return collection
}

// ******************************************
// * RTDB
// ******************************************
// Unpacks the RTDB-ness of a single Object.
type RTDBDSS = firebase.default.database.DataSnapshot
export function unpack_rtdb_obj<T>(
  query: RTDBDSS,
  id = true
) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (!query.exists()) {
    return undefined
  }

  const item: T & RTDBGT = query.val()

  if (id) {
    item.id = query.key!
  }

  return item
}

export function unpack_rtdb_obj_no_id<T>(
  query: RTDBDSS,
  id = true
) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (!query.exists()) {
    return undefined
  }

  return query.val() as T
}

// Unpacks the RTDB-ness of a single primitive.
export function unpack_rtdb_prim<T>(query: RTDBDSS) {
  const obj = {data: undefined, id: undefined}

  if (query instanceof Error) {
    console.error(query)
    return obj
  }

  if (!query.exists()) {
    return obj
  }

  return { data: query.val(), id: query.key! }
}

// Unpacks the RTDB-ness of a collection of objects.
export function unpack_rtdb_col<T>(query: RTDBDSS) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (!query.exists()) {
    return undefined
  }

  const col = {} as Record<RTDBG, T & RTDBGT>

  for (const [key, value] of Object.entries(query.val() || {})) {
    col[key] = Object.assign({}, value as any as T, { id: key })
  }

  return col
}

// Unpacks the RTDB-ness of a collection of primitives (e.g. Record<string, string>).
export function unpack_rtdb_prim_col<T>(query: RTDBDSS) {
  if (query instanceof Error) {
    console.error(query)
    return undefined
  }

  if (!query.exists()) {
    console.error('Query does not exist. returning.')
    return undefined
  }

  const col = {} as Record<RTDBG, T>

  for (const [key, value] of Object.entries(query.val() || {})) {
    col[key] = value as T
  }

  return col
}

export function unpack_rtdb_col_vals(query: RTDBDSS) {
  return (query && query.exists() && query.val()) || {}
}
