import { Timestamp } from '@firebase/firestore-types'
import { CONSTANTS } from '@ws/constants'

export type GUID = string
export type UID = string
export type T_Date_Number = number

export interface GUID_Type {
  id: GUID
}

// * Please alphabetize all interfaces (and their properties) below this comment for readability

// * Certain audit info should have the org attached.
export interface Audit_With_Org {
  by: Audit_With_Org_By
  on: T_Date_Number
  type?: CONSTANTS.CRUD_AUDIT_TYPE
}

export interface Audit_W_Org_W_TS {
  by: Audit_With_Org_By
  on: Timestamp
  type?: CONSTANTS.CRUD_AUDIT_TYPE
}

export interface Audit_With_Org_By {
  user: Audit_User_By
  organization: Audit_Org_By
}

// * Certain audit info does not have the org attached (i.e. inter-org stuff).
export interface Audit_WO_Org {
  by: Audit_User_By
  on: T_Date_Number
  type?: CONSTANTS.CRUD_AUDIT_TYPE
}

export interface Audit_User_By {
  id: UID
  name: string
}

export interface Audit_Org_By {
  id: UID
  name: string
}

export interface Event {
  audit: Event_Audit
  capacity: I_Event_Capacity
  is_test: boolean
  name: string
  window: I_Event_Window
}

export interface I_Event_Window {
  end: number
  offset: number
  start: number
  timezone: string
}

export interface Event_Audit {
  created: Audit_With_Org
  modified: Audit_With_Org
}

export interface I_Event_Capacity {
  [index: string]: number
}

export type T_Event_Capacity_Item = { key: string, cap: number}

export interface I_Event extends Omit<Event, 'dates' | 'audit'> {

}

export interface Event_Invitation {
  public: GUID,
}

export interface Job_Complete_Event {
  event: Event & GUID_Type
}

export interface Job_User_Name_Change {
  name: string
  user_id: string
}

export interface Organization {
  audit: Org_Audit_Data
  counts?: {
    members: number
  }
  // event?: Org_Events
  // id?: GUID
  lowercased_name?: string,
  name: string
  // member?: Org_Members
  // member_email?: Org_Emails
  // member_invitation?: Org_Invites
  urls?: Org_Urls
}

export interface Org_Urls {
  logos: Org_Logos
}

export interface Org_Logos {
  original: string
  thumbnail: string
}

export interface Org_Event {
  id: string
}

export interface Org_Audit_Data {
  created: Audit_WO_Org
  modified: Audit_WO_Org
}

export interface Org_Member {
  joined_on: Timestamp
  name: string
  role: ORG_MEMBER_ROLE
}

export interface Org_Member_Email {
  email: string
}

export interface Org_Member_Invite {
  // added on archiving invite
  closed_on?: Timestamp
  created: Audit_W_Org_W_TS
  email: string
  error?: any
  status: ORG_MEMBER_INVITATION_STATUS
  // Added on accepting invite
  user_id?: UID
}

export type Org_Events = Record<GUID, Org_Event>
export type Org_Members = Record<GUID, Org_Member>
export type Org_Emails = Record<GUID, Org_Member_Email>
export type Org_Invites = Record<GUID, Org_Member_Invite>

export enum ORG_MEMBER_INVITATION_STATUS {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  FAILED = 'failed',
  SENT = 'sent',
  SENDING = 'sending',
  UNSENT = 'unsent',
}

export enum ORG_MEMBER_ROLE {
  ADMIN = 'admin',
  MEMBER = 'member',
  OWNER = 'owner',
}

export interface I_User_Public_Org_Doc {
  1: 1
}

export interface User_Public {
  name: string
  // This is just a map to show orgs they belong to, no data needed.
  // organization: Record<GUID, User_Org_Doc>
  id: UID
}

// export type T_User_Public_Orgs =  Record<GUID, User_Org_Doc>

export interface User_Org_Doc {
  id: string
}


export interface User_Private {
  emails: {
    primary: string
    secondary?: string[]
  }
}

export interface User_App {
  last_org?: User_App_Last_Org
}

export interface User_App_Last_Org {
  id: string
  use: boolean
}

export interface User_Billing {
  credits: number
  stripe_id: string
}

export interface I_User_Ledger_Item {
  action: USER_LEDGER_ACTION
  // Number of credits for this entry.
  amount: number
  // Stripe payment intent ID. NB - some purchases are distributed to other accounts. This could be a charge_id from another account if the action is DISTRIBUTION.
  charge_id?: string | null
  created: T_Date_Number
  distro_from?: string
  distro_to?: string
  description?: string
  event_id?: string
  event_changes?: I_Billing_Event_Changes
  status?: USER_LEDGER_CREDIT_STATUS
  type: USER_LEDGER_TYPE
}

export interface I_User_Billing_Debit_Base extends I_User_Ledger_Item {
  event_id: string
}

export interface I_User_Billing_Debit_Event_Purchase extends I_User_Billing_Debit_Base {}

export interface I_User_Billing_Credit_Purchase extends I_User_Ledger_Item {
  charge_id: string | null // Stripe
  status: USER_LEDGER_CREDIT_STATUS
}

export interface I_User_Billing_Event_Change extends I_User_Ledger_Item {
  event_id: string
  event_changes: I_Billing_Event_Changes
}

export interface I_Billing_Event_Changes {
  capacity?: {
    old: number
    new: number
  }
  date_key?: {
    old: string
    new: string
  }
}

export enum USER_LEDGER_CREDIT_STATUS {
  COMPLETE = 'complete',
  ERROR = 'error',
  PROCESSING = 'processing',
  READY = 'ready',
}

export enum USER_LEDGER_TYPE {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum USER_LEDGER_ACTION {
  COMP = 'comp',
  CREDIT_PURCHASE = 'credit_purchase',
  DISTRIBUTION = 'distribution',
  EVENT_CHANGE = 'event_change',
  EVENT_CREATED = 'event_created',
  REFUND = 'refund',
}

export interface I_Archive_Event {
  id: string
}
